<template>
  <h3 v-if="heading" class="ContentProseA_Heading">
    <SkyLink
      inline
      :display="button"
      :dark="dark"
      :to="href"
      :target="target">
      <slot />
    </SkyLink>
  </h3>
  <SkyLink
    v-else
    inline
    :display="button"
    :button="button"
    :dark="dark"
    :to="href"
    :target="target">
    <slot />
  </SkyLink>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { SkyLink } from '@groveco/skylight'

const props = defineProps({
  href: {
    type: String,
    default: '',
  },
  target: {
    type: String,
    default: undefined,
  },
  class: {
    type: String,
    default: '',
  },
})

const heading = computed<boolean>(() => {
  return props.class.includes('h3')
})

const button = computed<boolean>(() => {
  return props.class.includes('button')
})

const dark = computed<boolean>(() => {
  return props.class.includes('button-grove-blue')
})
</script>

<style lang="scss">
.ContentProseA {
  &_Heading {
    margin-bottom: var(--spacing-2x);
  }
}
</style>
